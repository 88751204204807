import { Link } from "react-router-dom";
import styled from "@emotion/styled";

export const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
`;

export const StyledTopLeft = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const StyledNavLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;
