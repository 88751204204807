import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export const useManualPurchase = () => {
  const { mutate, ...rest } = useMutation({
    mutationFn: (variables) => {
      return axios.post(`/api/manualbuy`, variables);
    },
  });

  return [mutate, rest];
};
