import { format } from "date-fns";
import { ExportToCsv } from "export-to-csv";

export const useExporter = (columns) => {
  const exportToCsv = (name, data) => {
    const filename = `${name}_${format(new Date(), `yyyy-MM-dd-kkmmss`)}`;
    const csvOptions = {
      fieldSeparator: `,`,
      quoteStrings: `"`,
      decimalSeparator: `.`,
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
      filename,
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const exportData = data.map((d) => {
      return columns.reduce((acc, col) => {
        const value = col.hasOwnProperty(`exportAccessorKey`)
          ? d[col.exportAccessorKey]
          : d[col.accessorKey];

        return {
          ...acc,
          [col.header]: value ?? ``,
        };
      }, {});
    });

    csvExporter.generateCsv(exportData);
  };

  return exportToCsv;
};
