import * as React from "react";
import Draggable from "react-draggable";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";

const PaperComponent = (props) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={`[class*="MuiDialogContent-root"]`}
  >
    <Paper {...props} />
  </Draggable>
);

export const ConfirmationDialog = ({ open, onClose, onConfirm, symbol }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: `move` }} id="draggable-dialog-title">
        Buy {symbol}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to buy {symbol} now?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          No
        </Button>
        <Button onClick={onConfirm}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
