import React, { useState } from "react";

import { ConfirmationDialog } from "@app/components/confirmation-dialog/confirmation-dialog";
import { useManualPurchase } from "@app/requests/use-manual-purchase";

import { StyledCta } from "./manual-purchase-cta.styles";

export const ManualPurchaseCta = ({ symbol }) => {
  const [performManualPurchase] = useManualPurchase();
  const [confirmationDialogVisible, setConfirmationDialogVisible] =
    useState(false);

  const onClose = () => {
    setConfirmationDialogVisible(false);
  };

  const onConfirm = async () => {
    await performManualPurchase({ symbol });

    setConfirmationDialogVisible(false);
  };

  const onBuy = () => {
    setConfirmationDialogVisible(true);
  };

  return (
    <>
      <StyledCta onClick={onBuy}>BUY</StyledCta>
      <ConfirmationDialog
        open={confirmationDialogVisible}
        onClose={onClose}
        onConfirm={onConfirm}
        symbol={symbol}
      />
    </>
  );
};
