export const numericSortIgnoreNull = (direction) => (rowA, rowB, colId) => {
  const val1 = rowA.original[colId];
  const val2 = rowB.original[colId];

  if (val1 === undefined && val2 === undefined) {
    return 0;
  }

  if (val1 === undefined) {
    return direction ? -1 : 1;
  }

  if (val2 === undefined) {
    return direction ? 1 : -1;
  }

  return val1 - val2;
};
