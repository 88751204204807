import React from "react";
import { Button } from "@mui/material";

import { here } from "@app/helpers";

import { StyledLoginContainer } from "./login.styles";

export const Login = () => {
  const clientId = `DOZcVPEwmcJnPVPxkzjW7yemyUgs7Hjh`;
  const redirectUri = `${here()}/authorize?ptrt=${encodeURIComponent(here())}`;
  const scope = `openid profile offline_access MarketData ReadAccount Trade Crypto`;
  const state = Date.now();
  const audience = `https://api.tradestation.com`;
  const loginUrl = `https://signin.tradestation.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&audience=${audience}`;

  return (
    <StyledLoginContainer>
      <Button href={loginUrl} size="large" variant="contained">
        Login
      </Button>
    </StyledLoginContainer>
  );
};
