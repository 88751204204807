export const here = () => {
  const baseDomain = new URL(window.location.href);

  return baseDomain.origin;
};

export const noop = () => {};

export const numberOrEmpty = (optionalNumber) => {
  return Number.isNaN(optionalNumber) ? `` : optionalNumber;
};

export const formatNumber = (optionalNumber, decimalPlaces) => {
  return optionalNumber === null ||
    optionalNumber === undefined ||
    Number.isNaN(optionalNumber)
    ? ``
    : new Intl.NumberFormat().format(optionalNumber.toFixed(decimalPlaces));
};

export const formatBoolean = (optionalBool) => {
  if (optionalBool === true) return 1;
  if (optionalBool === false) return 0;

  return ``;
};
