import { format } from "date-fns";

export const formatShortDate = (dateStr) => {
  if (dateStr) {
    return format(new Date(dateStr), `ddMMMyy`).toUpperCase();
  }

  return ``;
};

export const formatTime = (dateStr) => {
  if (dateStr) {
    return format(new Date(dateStr), `kmm`);
  }

  return ``;
};

// this function gets around a strange JS date parsing issue where it doesn't handle 0001-01-01 date properly
// replace the date with todays date so it will take Day light saving into account too
export const getTimeActivationRulesDateTime = (
  timeActivationRuleDateTimeStr
) => {
  return new Date(
    timeActivationRuleDateTimeStr.replace(
      `0001-01-01`,
      format(new Date(), `yyyy-MM-dd`)
    )
  );
};
