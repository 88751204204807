import React from "react";

import { ManualPurchaseCta } from "@app/components/manual-purchase/manual-purchase-cta";
import { BuySuccessIcon } from "@app/icons/buy-success-icon";

import { StyledAICell } from "./ai-cell.styles";

export const AiCell = ({ ai, symbol, success, valid }) => {
  if (ai || success) {
    return (
      <StyledAICell>
        {ai || `MAN2`}
        {success && <BuySuccessIcon />}
      </StyledAICell>
    );
  }

  return valid ? <ManualPurchaseCta symbol={symbol} /> : null;
};
