import React from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const [cookies] = useCookies();
  const { id } = cookies;

  if (Boolean(id)) {
    return children;
  }

  return <Navigate to="/login" />;
};
