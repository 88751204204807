import { useEffect, useRef, useState } from "react";

export const useScreenerTableState = (id, initialValue = ``) => {
  const isFirstRender = useRef(true);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const persistedValue = sessionStorage.getItem(id);

    if (persistedValue) {
      setValue(JSON.parse(persistedValue));
    }

    isFirstRender.current = false;
  }, [id]);

  useEffect(() => {
    if (isFirstRender.current) return;

    sessionStorage.setItem(id, JSON.stringify(value));
  }, [id, value]);

  return [value, setValue];
};
