import React from "react";
import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";

import { OrderCard } from "@app/pages/orders/order-card";

const StyledContainer = styled(Grid)`
  background-color: ${({ theme }) =>
    theme.palette.mode === `dark` ? `#1A2027` : `#f1f1f1`};

  padding: 0 ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2)} 0;
`;

const StyledTitle = styled.h2`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`;

export const SymbolOrdersCard = ({ symbol, symbolOrders }) => {
  const [buyOrder, sellOrder1, sellOrder2] = symbolOrders.sort(
    ({ Legs: [{ BuyOrSell }] }) => (BuyOrSell === `Buy` ? -1 : 0)
  );

  return (
    <StyledContainer container spacing={2}>
      <Grid item xs={12}>
        <StyledTitle>{symbol}</StyledTitle>
      </Grid>
      <Grid item xs={6}>
        <OrderCard order={buyOrder} buy />
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2}>
          {sellOrder1 && <OrderCard order={sellOrder1} />}
          {sellOrder2 && <OrderCard order={sellOrder2} />}
        </Stack>
      </Grid>
    </StyledContainer>
  );
};
