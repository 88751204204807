import React, { useEffect, useMemo, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ColorModeContext } from "@app/contexts/color-mode";
import { Home, OrdersPage } from "@app/pages";
import { Login } from "@app/pages/login";
import { HistoricalOrdersPage } from "@app/pages/orders/historical-orders-page";

import { ProtectedRoute } from "./protected-route";

export const App = () => {
  const prefersDarkMode = useMediaQuery(`(prefers-color-scheme: dark)`);
  const [mode, setMode] = useState(prefersDarkMode ? `dark` : `light`);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === `light` ? `dark` : `light`));
      },
    }),
    [setMode]
  );

  useEffect(() => {
    setMode(prefersDarkMode ? `dark` : `light`);
  }, [prefersDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const router = createBrowserRouter([
    {
      path: `/orders`,
      element: (
        <ProtectedRoute>
          <OrdersPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `/historicorders`,
      element: (
        <ProtectedRoute>
          <HistoricalOrdersPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `/`,
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    {
      path: `login`,
      element: <Login />,
    },
  ]);

  return (
    <ColorModeContext.Provider
      value={{ toggleColorMode: colorMode.toggleColorMode, mode }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CookiesProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </LocalizationProvider>
        </CookiesProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
