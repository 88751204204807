import { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

import { noop } from "@app/helpers";

export const useRefreshToken = ({
  beforeRefresh = noop,
  afterRefresh = noop,
} = {}) => {
  const [cookies, setCookie] = useCookies();
  const { exp } = cookies;

  useEffect(() => {
    const refreshToken = (delay) => {
      setTimeout(async () => {
        await beforeRefresh();

        try {
          const {
            data: { exp },
          } = await axios.get(`/refreshToken`);

          setCookie(`exp`, exp);
          await afterRefresh();
        } catch (err) {
          console.log({ err });
        }
      }, delay);
    };

    const timeToRefresh = exp - Date.now();

    if (timeToRefresh <= 0) {
      refreshToken(0);
    } else {
      refreshToken(timeToRefresh);
    }
  }, [afterRefresh, beforeRefresh, exp, setCookie]);
};
