import styled from "@emotion/styled";

import backgroundImg from "./background.png";

export const StyledLoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${backgroundImg}) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
