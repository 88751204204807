import React from "react";

import { PageLayout } from "@app/components/page/page-layout";
import { ScreenerV2Table } from "@app/components/screener-table";

export const Home = () => {
  return (
    <PageLayout>
      <ScreenerV2Table />
    </PageLayout>
  );
};
