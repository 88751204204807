import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "date-fns";

import { useRefreshToken } from "@app/requests/use-refresh-token";

export const useOrders = ({ date } = {}) => {
  useRefreshToken();

  const dateStr = date ? format(new Date(date), `yyyy-MM-dd`) : ``;
  const { data = {} } = useQuery([`orders`, dateStr], async () => {
    const { data } = await axios.get(`/api/orders?date=${dateStr}`);

    return data;
  });

  const { orders = [] } = data;

  return { orders };
};
