import React from "react";

import { ColorModeContext } from "@app/contexts/color-mode";

import { StyledColorModeToggle } from "./color-mode-toggle.style";

export const ColorModeToggle = () => {
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);

  return (
    <StyledColorModeToggle
      onChange={toggleColorMode}
      checked={mode === `dark`}
    />
  );
};
