import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledCta = styled(Button)`
  text-decoration: underline;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;
