import React from "react";
import styled from "@emotion/styled";
import CancelSvg from "@mui/icons-material/Cancel";
import PendingSvg from "@mui/icons-material/Pending";
import { Paper, SvgIcon } from "@mui/material";
import { format } from "date-fns";

import { getTimeActivationRulesDateTime } from "@app/helpers/date-utils";
import { BuySuccessIcon } from "@app/icons/buy-success-icon";

const StyledOrderCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === `dark` ? `#1A2027` : `#fff`,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  height: `100%`,
}));

const StyledSubTitle = styled.h3`
  margin-top: 0;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDT = styled.dt``;
const StyledDD = styled.dd``;

const StyledDL = styled.dl``;

export const OrderCard = ({ order, buy }) => {
  const isOut = order.Status === `OUT`;
  const isFilled = !isOut && order.Status === `FLL`;
  const isPending = !isFilled && !isOut;

  return (
    <StyledOrderCard>
      <StyledRow>
        <StyledSubTitle aria-label={`order ID: ${order.OrderID}`}>
          {order.OrderID}
        </StyledSubTitle>
        {isOut && <SvgIcon component={CancelSvg} />}
        {isFilled && <SvgIcon component={BuySuccessIcon} />}
        {isPending && <SvgIcon component={PendingSvg} />}
      </StyledRow>
      <StyledDL>
        <StyledRow>
          <StyledDT>Status</StyledDT>
          <StyledDD>{order.Status}</StyledDD>
        </StyledRow>
        <StyledRow>
          <StyledDT>Order Type</StyledDT>
          <StyledDD>{order.OrderType}</StyledDD>
        </StyledRow>

        <StyledRow>
          <StyledDT>Quantity</StyledDT>
          <StyledDD>{order.Legs[0].QuantityOrdered}</StyledDD>
        </StyledRow>
        <StyledRow>
          <StyledDT>Buy Or Sell</StyledDT>
          <StyledDD>{order.Legs[0].BuyOrSell}</StyledDD>
        </StyledRow>
        <StyledRow>
          <StyledDT>ExecutionPrice</StyledDT>
          <StyledDD>{order.Legs[0].ExecutionPrice}</StyledDD>
        </StyledRow>
        {order.TrailingStop && (
          <StyledRow>
            <StyledDT>Trailing Stop</StyledDT>
            <StyledDD>{order.TrailingStop.Amount}</StyledDD>
          </StyledRow>
        )}
        {order.TimeActivationRules && (
          <StyledRow>
            <StyledDT>TimeActivationRules</StyledDT>
            <StyledDD>
              {format(
                getTimeActivationRulesDateTime(
                  order.TimeActivationRules[0].TimeUtc
                ),
                `HH:mm:ss`
              )}
            </StyledDD>
          </StyledRow>
        )}
      </StyledDL>
    </StyledOrderCard>
  );
};
