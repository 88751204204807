import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useId = (options) => {
  return useQuery(
    [`id`],
    async () => {
      const { data } = await axios.get(`/id`);

      return data;
    },
    options
  );
};
