import React from "react";
import { Grid } from "@mui/material";
import groupBy from "lodash.groupby";

import { PageLayout } from "@app/components/page/page-layout";
import { SymbolOrdersCard } from "@app/pages/orders/symbol-orders-card";
import { useOrders } from "@app/requests/use-orders";

export const OrdersPage = () => {
  const { orders } = useOrders();
  const ordersBySymbol = groupBy(orders, (order) => order.Legs[0].Symbol);

  console.log({ ordersBySymbol });

  const accountId = orders[0]?.AccountID;

  return (
    <PageLayout>
      {orders.length > 0 ? (
        <>
          <h1>Orders for {accountId}</h1>
          <Grid
            container
            padding={2}
            spacing={{ lg: 4 }}
            columns={{ xs: 4, lg: 6 }}
          >
            {Object.keys(ordersBySymbol).map((symbol) => (
              <Grid item xs={4} lg={3} key={symbol}>
                <SymbolOrdersCard
                  symbol={symbol}
                  symbolOrders={ordersBySymbol[symbol]}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <h1>No Orders</h1>
      )}
    </PageLayout>
  );
};
