import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { formatBoolean, formatNumber, numberOrEmpty } from "@app/helpers";
import { formatShortDate, formatTime } from "@app/helpers/date-utils";
import { useRefreshToken } from "@app/requests/use-refresh-token";

export const useScreenerV2Data = ({ enabled = true }) => {
  // const logger = useRequestLog();
  const [searchParams] = useSearchParams();

  useRefreshToken();

  const screenerUrl =
    searchParams.get(`mock`) === `true` ? `/mock/screener` : `/screenerv3`;

  const { data = {} } = useQuery(
    [`screenerData`],
    async () => {
      const { data } = await axios.get(screenerUrl);

      return data;
    },
    { refetchInterval: 1000, refetchIntervalInBackground: true, enabled }
  );

  const { candleStream = [], symbolsData } = data;

  const screenerData = candleStream.map((bar) => {
    const { last, hb } = bar;
    const { startDateTime, ticks, close, pSig, vSig, valid } = bar;
    const currentN = symbolsData?.find(({ T }) => T === bar.symbol)?.n;

    return {
      ...bar,
      aiComponent: {
        ai: bar.ai,
        success: bar.order,
        symbol: bar.symbol,
        valid: bar.valid,
      },
      currentN,
      id: startDateTime,
      time: startDateTime,
      date: startDateTime,
      totalTicks: ticks,
      last: numberOrEmpty(last),
      close,
      valid: formatBoolean(valid),
      hb: formatBoolean(hb),
      pSigLong: formatNumber(pSig, 4),
      vSigLong: formatNumber(vSig, 4),

      exportAi: bar.ai,
      exportTime: formatTime(startDateTime),
      exportDate: formatShortDate(startDateTime),
      exportRisk: formatNumber(bar.risk, 4),
      exportCurrentR: formatNumber(bar.currentR, 2),
      exportMinR: formatNumber(bar.minR, 2),
      exportMaxR: formatNumber(bar.maxR, 2),
      exportCPerc: formatNumber(bar.cPerc, 4),
      exportCapReq: bar.capReq?.toFixed(0) ?? ``,
      exportGP: formatNumber(bar.gp, 3),
      exportVcAv: formatNumber(bar.vcAV, 3),
    };
  });

  return { data: screenerData };
};
