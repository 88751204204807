import React, { useMemo } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { Box, IconButton, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";

import { useScreenerTableState } from "@app/components/screener-table/use-screener-table-state";
import { useExporter } from "@app/hooks/use-exporter";
import { useScreenerV2Data } from "@app/requests/use-screenerV2-data";

import { columns } from "./columns";

const SortingId = `sorting`;
const ColumnVisibilityId = `columnVisibility`;
const DensityId = `density`;

export const ScreenerV2Table = () => {
  const { data } = useScreenerV2Data({ enabled: true });
  const [sorting, setSorting] = useScreenerTableState(SortingId, []);
  const [density, setDensity] = useScreenerTableState(DensityId, `compact`);

  const [columnVisibility, setColumnVisibility] = useScreenerTableState(
    ColumnVisibilityId,
    {}
  );

  const handleExportRows = (rows) => {
    exportToCsv(
      `screener-view`,
      rows.map((row) => row.original)
    );
  };

  const handleExportData = () => {
    exportToCsv(`screener-all`, data);
  };

  const memorisedColumns = useMemo(
    () => columns({ sortDirection: sorting[0]?.desc }),
    [sorting]
  );

  const exportToCsv = useExporter(memorisedColumns);

  return (
    <MaterialReactTable
      columns={memorisedColumns}
      data={data}
      enableBottomToolbar={false}
      enableGlobalFilterModes={true}
      enablePagination={false}
      enableRowNumbers={false}
      enableRowVirtualization
      enableColumnResizing
      enableColumnDragging
      enableColumnOrdering
      onColumnVisibilityChange={setColumnVisibility}
      onSortingChange={setSorting}
      onDensityChange={setDensity}
      state={{
        columnVisibility,
        sorting,
        density,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box sx={{ display: `flex`, gap: `1rem`, flexWrap: `wrap` }}>
          <Tooltip
            title="Export all data that is currently in the table (ignores sorting, filtering, etc.)"
            arrow
          >
            <IconButton aria-label="Export All Data" onClick={handleExportData}>
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Export all rows (still respects filtering and sorting)"
            arrow
          >
            <span>
              <IconButton
                aria-label="Export All Rows"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
              >
                <GetAppOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
    />
  );
};
