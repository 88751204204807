import React from "react";
import { useCookies } from "react-cookie";
import { Box, Typography } from "@mui/material";

import { DefaultAppBar } from "@app/components/default-app-bar";
import { useId } from "@app/requests/use-id";

export const PageLayout = ({ children }) => {
  const [cookies] = useCookies();
  const { id } = cookies;

  const { data: profile } = useId({ enabled: Boolean(id) });

  return (
    <Box
      sx={{
        bgcolor: `background.default`,
        color: `text.primary`,
        display: `flex`,
        flexDirection: `column`,
        minHeight: `100vh`,
      }}
    >
      <DefaultAppBar profile={profile} />
      <Box
        sx={{
          padding: `0.5rem 1.5rem 1.5rem 1.5rem`,
          flex: 1,
        }}
      >
        {children}
      </Box>
      <Typography
        variant="body2"
        component="footer"
        sx={{
          padding: `1.5rem`,
          textAlign: `center`,
        }}
        translate="no"
      >
        Vault Capital is a trading style of Vault Capital Group Ltd. Registered
        Office: 1103, 2 Old Mount Street, Manchester, M4 4GQ. Vault Capital
        Group Ltd is registered in England & Wales. Company Registration Number:
        13809707. Copyright © 2022 Vault Capital. All rights reserved.
      </Typography>
    </Box>
  );
};
