import React from "react";
import { format } from "date-fns";

import { AiCell } from "@app/components/ai-cell/ai-cell";
import { numericSortIgnoreNull } from "@app/components/screener-table/sorting-fns";
import { formatNumber } from "@app/helpers";
import { formatShortDate, formatTime } from "@app/helpers/date-utils";

export const columns = ({ sortDirection }) => [
  {
    accessorKey: `time`,
    header: `Time`,
    size: 120,
    Cell: ({ cell }) => formatTime(cell.getValue()),
    exportAccessorKey: `exportTime`,
  },
  {
    accessorKey: `date`,
    header: `Date`,
    size: 120,
    Cell: ({ cell }) => formatShortDate(cell.getValue()),
    exportAccessorKey: `exportDate`,
  },
  { accessorKey: `symbol`, header: `Symbol`, size: 100 },
  {
    accessorKey: `aiComponent`,
    exportAccessorKey: `exportAi`,
    header: `AI`,
    size: 100,
    Cell: ({ cell }) => <AiCell {...cell.getValue()} />,
    sortingFn: (
      { original: { ai: ai1 = `` } },
      { original: { ai: ai2 = `` } }
    ) => ai1.localeCompare(ai2),
  },
  {
    accessorKey: `float`,
    header: `Float`,
    size: 140,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 1),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `currentTotalVolume`,
    header: `V`,
    size: 140,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 3),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `vcAV`,
    exportAccessorKey: `exportVcAv`,
    header: `AV`,
    size: 140,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 3),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `gp`,
    exportAccessorKey: `exportGP`,
    header: `GP`,
    size: 140,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 3),
    filterVariant: `range`,
    filterFn: `between`,
  },
  // {
  //   accessorKey: `ticksDaily`,
  //   header: `Ticks (Daily)`,
  //   size: 170,
  // },
  {
    accessorKey: `totalTicks`,
    header: `Ticks (Bar)`,
    size: 160,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 1),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `minN`,
    header: `minN`,
    size: 120,
    Cell: ({ cell, row }) =>
      `${cell.getValue()} (${format(
        new Date(row.original.minNDate),
        `H:mm:ss X`
      )})`,
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `n`,
    header: `n`,
    size: 120,
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `currentN`,
    header: `Current N`,
    size: 120,
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `close`,
    header: `Close`,
    size: 120,
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `last`,
    header: `Last`,
    size: 120,
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `pSig`,
    exportAccessorKey: `pSigLong`,
    header: `PSig`,
    size: 120,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 2),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `vSig`,
    exportAccessorKey: `vSigLong`,
    header: `VSig`,
    size: 120,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 2),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `valid`,
    header: `Valid`,
    size: 120,
  },
  {
    accessorKey: `risk`,
    header: `Risk`,
    size: 120,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 4),
    filterVariant: `range`,
    filterFn: `between`,
    exportAccessorKey: `exportRisk`,
  },
  {
    accessorKey: `currentR`,
    header: `CurrentR`,
    size: 150,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 2),
    filterVariant: `range`,
    filterFn: `between`,
    exportAccessorKey: `exportCurrentR`,
    sortUndefined: false,
    sortingFn: numericSortIgnoreNull(sortDirection),
  },
  {
    accessorKey: `minR`,
    header: `MinR`,
    size: 120,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 2),
    filterVariant: `range`,
    filterFn: `between`,
    exportAccessorKey: `exportMinR`,
    sortUndefined: false,
    sortingFn: numericSortIgnoreNull(sortDirection),
  },
  {
    accessorKey: `maxR`,
    header: `MaxR`,
    size: 120,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 2),
    filterVariant: `range`,
    filterFn: `between`,
    exportAccessorKey: `exportMaxR`,
    sortUndefined: false,
    sortingFn: numericSortIgnoreNull(sortDirection),
  },
  {
    accessorKey: `cPerc`,
    header: `CPerc`,
    size: 130,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 4),
    filterVariant: `range`,
    filterFn: `between`,
    exportAccessorKey: `exportCPerc`,
  },
  {
    accessorKey: `shares`,
    header: `Shares`,
    size: 130,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 0),
    filterVariant: `range`,
    filterFn: `between`,
  },
  {
    accessorKey: `capReq`,
    header: `CapReq`,
    size: 140,
    Cell: ({ cell }) => formatNumber(cell.getValue(), 0),
    filterVariant: `range`,
    filterFn: `between`,
    exportAccessorKey: `exportCapReq`,
  },
  // {
  //   accessorKey: `hb`,
  //   header: `HB`,
  //   size: 120,
  // },
];
